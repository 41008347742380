interface iErrorMessages {
  server: {
    [key: number | string]: string;
  };
}

export const defaultErrorMessage = "serverErrors.default";

export const errorMessages: iErrorMessages = {
  server: {
    500: "serverErrors.server.500",
    401: "serverErrors.server.401",
    502: "serverErrors.server.502",
    OFF: "serverErrors.server.offline",
    DEFAULT: "serverErrors.default"
  },
};

import { toast } from "@/utils/toastHandler";
import posthog from "posthog-js";
import { readonly, ref } from "vue";
import { useRouter } from "vue-router";
import { i18n } from "@/plugins/i18n";

const isRecording = ref(false);
const router = useRouter();

export function usePostHog(user?: { id: string; username: string }) {
  const POSTHOG_API_KEY = import.meta.env.VITE_APP_POSTHOG_API_KEY || "";

  if (!POSTHOG_API_KEY || POSTHOG_API_KEY === "") {
    return {
      posthog,
      isRecording: readonly(isRecording),
      startRecording,
      stopRecording,
      toggleRecording,
    };
  }

  // Initialize PostHog if not already initialized
  if (!posthog.__loaded) {
    posthog.init(POSTHOG_API_KEY, {
      api_host: "https://us.i.posthog.com",
      disable_session_recording: true,
    });

    if (user) {
      posthog.identify(user.id, {
        username: user.username,
      });
    }
  }

  isRecording.value = posthog.sessionRecordingStarted();

  function startRecording() {
    isRecording.value = true;
    posthog.opt_in_capturing();
    posthog._start_queue_if_opted_in();
    posthog.startSessionRecording();

    toast.success(i18n.global.t("profile.recording_started"));
    router.push("/");
  }

  function stopRecording() {
    isRecording.value = false;
    posthog.stopSessionRecording();
    posthog.opt_out_capturing();

    toast.success(i18n.global.t("profile.recording_sent"));
    window.location.reload();
  }

  function toggleRecording() {
    if (isRecording.value) {
      stopRecording();
    } else {
      startRecording();
    }
  }

  return {
    posthog,
    isRecording: readonly(isRecording),
    startRecording,
    stopRecording,
    toggleRecording,
  };
}

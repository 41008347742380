import { usePostHog } from "@/composables/usePostHog";
import SETUP_ROUTE_GAURD from "@/middleware/route-guard";
import new_routes from "@/router/routes";
import { createRouter, createWebHistory } from "vue-router";

const setupRouter = async () => {
  const router = createRouter({
    history: createWebHistory(),
    routes: new_routes,
    scrollBehavior(to, from, savedPosition) {
      if (!to.meta.keepPosition && !from.meta.keepPosition) {
        if (savedPosition) {
          return { top: 0 };
        } else {
          return { top: 0 };
        }
      }
      return;
    },
  });

  SETUP_ROUTE_GAURD(router);

  const { posthog } = usePostHog();

  router.afterEach((to, from) => {
    if (posthog) {
      // capture page views
      posthog.capture("$pageview");

      // capture page leave
      if (from.path !== to.path) {
        posthog.capture("$pageleave");
      }
    }
  });

  return router;
};

export const router = setupRouter();

import { cardsRoutes } from "@/pages/cards/cards.routes";
import { giftCardRoutes } from "@/pages/giftcards/giftcards.routes";
import { billsRoutes } from "@/pages/payments/payments.routes";
import { tradeRoutes } from "@/pages/trade-crypto/routes";
import { transactionRoutes } from "@/pages/transactions/transactions.routes";
import { depositRoutes } from "@/pages/wallet/actions/deposit/deposit.routes";
import { swapRoutes } from "@/pages/wallet/actions/trade/swap.routes";
import { withdrawalRoutes } from "@/pages/wallet/actions/withdraw/withdraw.routes";
import { walletsRoute } from "@/pages/wallet/routes";
import { MIDDLEWARES, ROUTES } from "@/utils/consts";
import { RouteRecordRaw } from "vue-router";

const maintenanceMode = import.meta.env.VITE_APP_MAINTENANCE_MODE as string;

// Maintenance mode routes
const maintenanceRoutes = [
  {
    path: "/:pathMatch(.*)*",
    name: ROUTES.MAINTENANCE.name,
    component: () => import("@/pages/maintenance-mode.vue"),
  },
];

// Authentication related routes
const authenticationRoutes: RouteRecordRaw[] = [
  {
    path: ROUTES.LOGIN.path,
    name: ROUTES.LOGIN.name,
    component: () => import("@/pages/login.vue"),
  },
  {
    path: ROUTES.CREATE_ACCOUNT.path,
    name: ROUTES.CREATE_ACCOUNT.path,
    component: () => import("@/pages/create-account/index.vue"),
  },
  {
    path: ROUTES.CREATE_ACCOUNT.TELL_US_MORE.path,
    name: ROUTES.CREATE_ACCOUNT.TELL_US_MORE.name,
    component: () => import("@/pages/create-account/tell-us-more.vue"),
  },
  {
    path: ROUTES.RESET_PASSWORD.path,
    name: ROUTES.RESET_PASSWORD.name,
    component: () => import("@/pages/reset-password.vue"),
  },
  {
    path: ROUTES.REDIRECT_TOKEN.path,
    name: ROUTES.REDIRECT_TOKEN.name,
    component: () => import("@/pages/redirect-token-authentication.vue"),
    beforeEnter: (to, _from, next) => {
      if (!to.query["redirect-token"]) {
        return next({ name: ROUTES.LOGIN.name });
      }
      return next();
    },
  },
];

// Profile routes
const profileRoutes = {
  path: ROUTES.PROFILE.path,
  name: ROUTES.PROFILE.name,
  component: () => import("@/pages/profile/index.vue"),
  meta: {
    middlewares: { [MIDDLEWARES.PROTECTED]: true },
    displayName: ROUTES.PROFILE.displayName,
  },
  children: [
    {
      path: "",
      name: ROUTES.PROFILE.INFORMATION.name,
      component: () => import("@/pages/profile/PersonalInformation.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.PROFILE.INFORMATION.displayName,
      },
      props: { backPath: "/profile" },
      children: [
        {
          path: ROUTES.PROFILE.USERNAME_UPDATE.path,
          name: ROUTES.PROFILE.USERNAME_UPDATE.name,
          component: () => import("@/pages/profile/UsernameUpdate.vue"),
        },
        {
          path: ROUTES.PROFILE.EMAIL_UPDATE.path,
          name: ROUTES.PROFILE.EMAIL_UPDATE.name,
          component: () => import("@/pages/profile/updateIdentifier.vue"),
        },
      ],
    },
  ],
};

// Profile account routes

const profileAccountRoutes = {
  path: ROUTES.PROFILE.ACCOUNT.path,
  name: ROUTES.PROFILE.ACCOUNT.name,
  component: () => import("@/pages/profile/AccountSettings.vue"),
  meta: {
    middlewares: { [MIDDLEWARES.PROTECTED]: true },
    displayName: ROUTES.PROFILE.ACCOUNT.displayName,
  },
  children: [
    {
      path: ROUTES.PROFILE.UPGRADE.path,
      name: ROUTES.PROFILE.UPGRADE.name,
      component: () => import("@/pages/profile/AccountUpgrade.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.PROFILE.ACCOUNT.displayName,
      },
      props: {
        backPath: "/profile",
      },
    },
    {
      path: ROUTES.PROFILE.GENERAL_SETTINGS.path,
      name: ROUTES.PROFILE.GENERAL_SETTINGS.name,
      component: () => import("@/pages/profile/GeneralSettings.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.PROFILE.ACCOUNT.displayName,
      },
      props: {
        backPath: "/profile",
      },
    },
    {
      path: ROUTES.PROFILE.SECURITY.path,
      name: ROUTES.PROFILE.SECURITY.name,
      component: () => import("@/pages/profile/LoginAndSecurity.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.PROFILE.ACCOUNT.displayName,
      },
      props: {
        backPath: "/profile",
      },
    },
    // {
    //   path: ROUTES.PROFILE.GENERAL.path,
    //   name: ROUTES.PROFILE.GENERAL.name,
    //   component: () => import("@/pages/profile/General.vue"),
    //   meta: {
    //     middlewares: { [MIDDLEWARES.PROTECTED]: true },
    //     displayName: ROUTES.PROFILE.ACCOUNT.displayName,
    //   },
    //   props: {
    //     backPath: "/profile",
    //   },
    // },
  ],
};

// Business profile routes
const businessProfileRoutes = {
  path: ROUTES.PROFILE.BUSINESS.path,
  name: ROUTES.PROFILE.BUSINESS.name,
  component: () => import("@/pages/profile/BusinessIntegrationIndex.vue"),
  meta: {
    middlewares: { [MIDDLEWARES.PROTECTED]: true },
    displayName: ROUTES.PROFILE.BUSINESS.displayName,
  },
  props: { backPath: "/profile" },
  children: [
    // Business Main Page
    {
      path: ROUTES.PROFILE.BUSINESS.path,
      name: ROUTES.PROFILE.BUSINESS.name,
      component: () => import("@/pages/profile/BusinessIntegration.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.PROFILE.BUSINESS.displayName,
      },
      props: { backPath: "/profile" },
    },

    // Business Deposit Section
    {
      path: ROUTES.PROFILE.BUSINESS_DEPOSIT.path,
      name: ROUTES.PROFILE.BUSINESS_DEPOSIT.name,
      component: () => import("@/pages/profile/BusinessDeposit.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.PROFILE.BUSINESS_DEPOSIT.displayName,
        module: "businessDeposit",
      },
      props: { backPath: ROUTES.PROFILE.BUSINESS.path },
      children: [
        // Business Deposit Address
        {
          path: "",
          name: ROUTES.PROFILE.BUSINESS_DEPOSIT_ADDRESS.name,
          component: () =>
            import(
              "@/pages/profile/business-deposit/businessDepositAddress.vue"
            ),
          meta: {
            middlewares: { [MIDDLEWARES.PROTECTED]: true },
            displayName: ROUTES.PROFILE.BUSINESS_DEPOSIT_ADDRESS.displayName,
            module: "businessDeposit",
            subModule: "businessDepositAddress",
          },
          props: { backPath: "/profile/business" },
        },

        // Business Deposit Wallets
        {
          path: ROUTES.PROFILE.BUSINESS_DEPOSIT_WALLETS.path,
          name: ROUTES.PROFILE.BUSINESS_DEPOSIT_WALLETS.name,
          component: () =>
            import(
              "@/pages/profile/business-deposit/businessDepositWallets.vue"
            ),
          meta: {
            middlewares: { [MIDDLEWARES.PROTECTED]: true },
            displayName: ROUTES.PROFILE.BUSINESS_DEPOSIT_WALLETS.displayName,
            module: "businessDeposit",
            subModule: "businessDepositWallets",
          },
          props: { backPath: ROUTES.PROFILE.BUSINESS.path },
          children: [
            {
              path: ROUTES.PROFILE.BUSINESS_DEPOSIT_WALLETS.COLLECT.path,
              name: ROUTES.PROFILE.BUSINESS_DEPOSIT_WALLETS.COLLECT.name,
              component: () =>
                import("@/pages/profile/business-deposit/actions/index.vue"),
              meta: {
                middlewares: { [MIDDLEWARES.PROTECTED]: true },
                displayName:
                  ROUTES.PROFILE.BUSINESS_DEPOSIT_WALLETS.displayName,
                module: "businessDepositWallets",
                subModule: "businessDepositDialog",
              },
              props: { backPath: ROUTES.PROFILE.BUSINESS_DEPOSIT_WALLETS.path },
            },
          ],
        },

        // Business Deposit History
        {
          path: ROUTES.PROFILE.BUSINESS_DEPOSIT_HISTORY.path,
          name: ROUTES.PROFILE.BUSINESS_DEPOSIT_HISTORY.name,
          component: () =>
            import(
              "@/pages/profile/business-deposit/businessDepositHistory.vue"
            ),
          meta: {
            middlewares: { [MIDDLEWARES.PROTECTED]: true },
            displayName: ROUTES.PROFILE.BUSINESS_DEPOSIT_HISTORY.displayName,
            module: "businessDeposit",
            subModule: "businessDepositHistory",
          },
          props: { backPath: ROUTES.PROFILE.BUSINESS.path },
        },

        // Business Collection History
        {
          path: ROUTES.PROFILE.BUSINESS_COLLECT_HISTORY.path,
          name: ROUTES.PROFILE.BUSINESS_COLLECT_HISTORY.name,
          component: () =>
            import(
              "@/pages/profile/business-deposit/businessCollectionHistory.vue"
            ),
          meta: {
            middlewares: { [MIDDLEWARES.PROTECTED]: true },
            displayName: ROUTES.PROFILE.BUSINESS_COLLECT_HISTORY.displayName,
            module: "businessDeposit",
            subModule: "businessDepositCollectHistory",
          },
          props: { backPath: ROUTES.PROFILE.BUSINESS.path },
        },

        // Business Gas Station
        {
          path: ROUTES.PROFILE.BUSINESS_GAS_STATION.path,
          name: ROUTES.PROFILE.BUSINESS_GAS_STATION.name,
          component: () =>
            import(
              "@/pages/profile/business-deposit/businessDepositGasStation.vue"
            ),
          meta: {
            middlewares: { [MIDDLEWARES.PROTECTED]: true },
            displayName: ROUTES.PROFILE.BUSINESS_GAS_STATION.displayName,
            module: "businessDeposit",
            subModule: "businessDepositGasStation",
          },
          props: { backPath: ROUTES.PROFILE.BUSINESS.path },
        },

        // Business Callback Log
        {
          path: ROUTES.PROFILE.BUSINESS_CALLBACK_LOG.path,
          name: ROUTES.PROFILE.BUSINESS_CALLBACK_LOG.name,
          component: () =>
            import(
              "@/pages/profile/business-deposit/businessDepositWebhookLog.vue"
            ),
          meta: {
            middlewares: { [MIDDLEWARES.PROTECTED]: true },
            displayName: ROUTES.PROFILE.BUSINESS_CALLBACK_LOG.displayName,
            module: "businessDepositCallbackLog",
            subModule: "businessDepositCallbackLog",
          },
          props: { backPath: ROUTES.PROFILE.BUSINESS.path },
        },
      ],
    },

    // crypto withdrawal section
    {
      name: ROUTES.PROFILE.BUSINESS.CRYPTO_WITHDRAWAL.name,
      path: ROUTES.PROFILE.BUSINESS.CRYPTO_WITHDRAWAL.path,
      component: () => import("@/pages/profile/CryptoWithdrawal.vue"),
      redirect:
        ROUTES.PROFILE.BUSINESS.CRYPTO_WITHDRAWAL.WITHDRAWAL_HISTORY.path,
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.PROFILE.BUSINESS.CRYPTO_WITHDRAWAL.displayName,
      },
      children: [
        {
          name: ROUTES.PROFILE.BUSINESS.CRYPTO_WITHDRAWAL.WITHDRAWAL_HISTORY
            .name,
          path: "",
          component: () =>
            import("@/pages/profile/crypto-withdrawal/WithdrawalHistory.vue"),
          meta: {
            middlewares: { [MIDDLEWARES.PROTECTED]: true },
            displayName:
              ROUTES.PROFILE.BUSINESS.CRYPTO_WITHDRAWAL.WITHDRAWAL_HISTORY
                .displayName,
          },
        },
        {
          name: ROUTES.PROFILE.BUSINESS.CRYPTO_WITHDRAWAL.WITHDRAWAL_POOL.name,
          path: ROUTES.PROFILE.BUSINESS.CRYPTO_WITHDRAWAL.WITHDRAWAL_POOL.path,
          component: () =>
            import("@/pages/profile/crypto-withdrawal/WithdrawalPool.vue"),
          meta: {
            middlewares: { [MIDDLEWARES.PROTECTED]: true },
            displayName:
              ROUTES.PROFILE.BUSINESS.CRYPTO_WITHDRAWAL.WITHDRAWAL_POOL
                .displayName,
          },
        },
        {
          name: ROUTES.PROFILE.BUSINESS.CRYPTO_WITHDRAWAL.WEBHOOK_LOG.name,
          path: ROUTES.PROFILE.BUSINESS.CRYPTO_WITHDRAWAL.WEBHOOK_LOG.path,
          component: () =>
            import("@/pages/profile/crypto-withdrawal/WebhookLog.vue"),
          meta: {
            middlewares: { [MIDDLEWARES.PROTECTED]: true },
            displayName:
              ROUTES.PROFILE.BUSINESS.CRYPTO_WITHDRAWAL.WEBHOOK_LOG.displayName,
          },
        },
      ],
    },

    // Business Virtual Cards Section
    {
      path: ROUTES.PROFILE.BUSINESS_VIRTUAL_CARDS.path,
      name: ROUTES.PROFILE.BUSINESS_VIRTUAL_CARDS.name,
      component: () => import("@/pages/profile/BusinessVirtualCards.vue"),
      meta: {
        middlewares: { [MIDDLEWARES.PROTECTED]: true },
        displayName: ROUTES.PROFILE.BUSINESS.displayName,
      },
      props: { backPath: "/profile" },
      children: [
        // Business Card Details
        {
          name: ROUTES.PROFILE.BUSINESS_VIRTUAL_CARD.name,
          path: ROUTES.PROFILE.BUSINESS_VIRTUAL_CARD.path,
          component: () =>
            import("@/pages/profile/virtual-cards/BusinessCardDetails.vue"),
          meta: {
            middlewares: { [MIDDLEWARES.PROTECTED]: true },
            displayName: ROUTES.PROFILE.BUSINESS.displayName,
          },
        },
        // Business Master Card
        {
          name: ROUTES.PROFILE.BUSINESS_VIRTUAL_MASTER_CARD.name,
          path: ROUTES.PROFILE.BUSINESS_VIRTUAL_MASTER_CARD.path,
          component: () =>
            import("@/pages/profile/virtual-cards/VirtualMasterCards.vue"),
          meta: {
            middlewares: { [MIDDLEWARES.PROTECTED]: true },
            displayName: ROUTES.PROFILE.BUSINESS.displayName,
          },
        },
        // Business Visa Card
        {
          name: ROUTES.PROFILE.BUSINESS_VIRTUAL_VISA_CARD.name,
          path: ROUTES.PROFILE.BUSINESS_VIRTUAL_VISA_CARD.path,
          component: () =>
            import("@/pages/profile/virtual-cards/VirtualVisa.vue"),
          meta: {
            middlewares: { [MIDDLEWARES.PROTECTED]: true },
            displayName: ROUTES.PROFILE.BUSINESS.displayName,
          },
        },
      ],
    },
  ],
};

// Business deposit address detail route
const businessDepositAddressDetailRoute = {
  path: ROUTES.PROFILE.BUSINESS_DEPOSIT_ADDRESS_DETAIL.path,
  name: ROUTES.PROFILE.BUSINESS_DEPOSIT_ADDRESS_DETAIL.name,
  component: () =>
    import("@/pages/profile/business-deposit/businessDepositAddressDetail.vue"),
  meta: {
    middlewares: { [MIDDLEWARES.PROTECTED]: true },
    displayName: ROUTES.PROFILE.BUSINESS_DEPOSIT_ADDRESS_DETAIL.displayName,
  },
  props: {
    backPath: "/profile/business",
  },
};

// Email verification route
const emailVerificationRoute = {
  path: "/pd/c/:identifier_type/:token",
  name: "Verify Email",
  component: () => import("@/pages/confirm-change.vue"),
};

// 404 route
const notFoundRoute = {
  name: "NotFound",
  path: "/:pathMatch(.*)*",
  component: () => import("@/pages/404.vue"),
};

// Combine all routes
const routes = [
  ...(maintenanceMode === "true"
    ? maintenanceRoutes
    : [
        ...authenticationRoutes,
        {
          path: ROUTES.INDEX.path,
          name: ROUTES.INDEX.name,
          component: () => import("@/authView"),
          children: [
            {
              path: "",
              name: ROUTES.INDEX.name,
              component: () => import("@/pages/index.vue"),
              meta: {
                middlewares: { [MIDDLEWARES.PROTECTED]: true },
                displayName: ROUTES.INDEX.displayName,
              },
              children: [
                depositRoutes,
                withdrawalRoutes,
                swapRoutes,
                billsRoutes,
                {
                  name: ROUTES.TRANSACTION_OVERVIEW_DETAILS.name,
                  path: ROUTES.TRANSACTION_OVERVIEW_DETAILS.path,
                  component: () => import("@/pages/transactions/show.vue"),
                  meta: {
                    keepPosition: true,
                    displayName: ROUTES.TRANSACTION_OVERVIEW_DETAILS.name,
                  },
                },
                {
                  path: ROUTES.BET_TOP_UP.path,
                  name: ROUTES.BET_TOP_UP.name,
                  component: () =>
                    import("@/pages/payments/actions/bet-top-up/index.vue"),
                  meta: {
                    middlewares: { [MIDDLEWARES.PROTECTED]: true },
                    displayName: ROUTES.BET_TOP_UP.displayName,
                  },
                },
              ],
            },
            giftCardRoutes,
            profileRoutes,
            profileAccountRoutes,
            businessProfileRoutes,
            businessDepositAddressDetailRoute,
            transactionRoutes,
            ...tradeRoutes,
            ...walletsRoute,
            cardsRoutes,
            {
              path: ROUTES.REFERRALS.path,
              name: ROUTES.REFERRALS.name,
              component: () => import("@/pages/referrals/index.vue"),
              meta: {
                middlewares: { [MIDDLEWARES.PROTECTED]: true },
                displayName: ROUTES.REFERRALS.displayName,
              },
            },
          ],
        },
        emailVerificationRoute,
        notFoundRoute,
      ]),
];

export default routes;

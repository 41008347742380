import { AxiosHeaders, AxiosInstance } from "axios";
import EventBus from "@/utils/event-bus";
import { EVENTBUS, ROUTES } from "@/utils/consts";
import { toast } from "@/utils/toastHandler";
import { errorMessages } from "@/utils/consts/errorMessages";
import authService from "@/services/auth.service";
import { useCookies } from "vue3-cookies";
import { ValidationRequiredCode } from "@/shared/types";
import { i18n } from "@/plugins/i18n";

// Track whether the network error toast has been displayed
let hasToastedNetworkError = false;
let hasToastedUnauthorizedError = false;

export const setupInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    (config) => {
      const { cookies } = useCookies();
      const token = cookies.get("token");

      if (token) {
        if (config.headers) {
          config.headers["Authorization"] = `Bearer ${token}`;
        } else {
          config.headers = { Authorization: `Bearer ${token}` } as unknown as AxiosHeaders;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    (res) => {
      // Set the network error flag to false, this will allow the toast to be displayed again
      hasToastedNetworkError = false;
      hasToastedUnauthorizedError = false;
      return res;
    },
    async (err) => {
      // Toast Network Error
      if (err.code === "ERR_NETWORK" && !hasToastedNetworkError) {
        toast.error(i18n.global.t(errorMessages.server.OFF));

        // Set the network error flag to true to prevent the toast from being displayed again
        hasToastedNetworkError = true;
      }

      // Toast Server Error
      if (err.response) {
        const { status } = err.response;
        const originalConfig = err.config;

        if (status === 500 || status === 502) {
          toast.error(i18n.global.t(errorMessages.server[status]));
        }
        if (status === 423) {
          const { message } = err.response.data;
          EventBus.dispatch(EVENTBUS.SHOW_SERVICE_ERROR, {
            message: message || errorMessages.server[status],
          });
        }

        if (status === 406) {
          const { message, errorCode } = err.response.data;
          if (
            errorCode === ValidationRequiredCode.EMAIL_VERIFICATION_REQUIRED
          ) {
            EventBus.dispatch(EVENTBUS.SHOW_QUICKLINK_VALIDATION_ERROR, {
              type: "email",
              message,
            });
          }
          if (
            errorCode === ValidationRequiredCode.PHONE_VERIFICATION_REQUIRED
          ) {
            EventBus.dispatch(EVENTBUS.SHOW_QUICKLINK_VALIDATION_ERROR, {
              type: "phone",
              message,
            });
          }
          if (errorCode === ValidationRequiredCode.KYC_REQUIRED) {
            EventBus.dispatch(EVENTBUS.SHOW_QUICKLINK_VALIDATION_ERROR, {
              type: "kyc",
              message,
            });
          }
        }

        if (originalConfig.url !== authService.loginRoute) {
          // Access Token was expired
          if (
            status === 401 &&
            !originalConfig._retry &&
            !hasToastedUnauthorizedError
          ) {
            toast.error(i18n.global.t(errorMessages.server[status]));
            EventBus.dispatch(EVENTBUS.LOGOUT);
            hasToastedUnauthorizedError = true;
          }
        } else {
          const { cookies } = useCookies();
          const token = cookies.get("token");

          if (status === 401 && token) {
            toast.warning("You are already logged in");
            setTimeout(() => {
              window.location.replace(ROUTES.INDEX.path);
            }, 1500);
          }
        }
      }

      return Promise.reject(err);
    },
  );
};

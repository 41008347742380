import { defineAsyncComponent } from "vue";

export const HEADER_CLASS_ONSCROLL = "shadow backdrop-blur";
export const BREAKPOINTS = {
  lsm: "640px",

  smd: "768px",

  sm: "878px",
  // => @media (min-width: 878px) { ... }

  md: "1024px",
  // => @media (min-width: 968px) { ... }

  lg: "1240px",
  // => @media (min-width: 1024px) { ... }

  xl: "1280px",
  // => @media (min-width: 1280px) { ... }

  "2xl": "1536px",
  // => @media (min-width: 1536px) { ... }
};

export const MIDDLEWARES = {
  PROTECTED: "protected",
};

export const VALIDATION_ERRORS = {
  REQUIRED_EMAIL: "Email address is required",
  REQUIRED_PHONE: "Phone number is required",
  REQUIRED_PASSWORD: "Password is required",
  VALID_EMAIL: "Please enter a valid email address",
  VALID_PASSWORD:
    "Use 8 or more characters with a mix of letters, numbers & symbols",
  PASSWORD_LENGTH_ERROR: "Password should be at least 8 characters long",
  INVALID_MIN_CARD_AMOUNT: "minimum card value to ",
  INVALID_MAX_CARD_AMOUNT: "maximium card value to ",
  REFERRAL_CODE_LENGTH_ERROR: "Referral code should be 6 characters long",
  TERMS_AND_CONDITIONS: "Please accept the terms and conditions",
};

export enum CHANNELS {
  EMAIL = "email",
  PHONE = "phone",
}

export const TOKEN_COUNTDOWN_TIME = 1000; //format in milliseconds

export const VALIDATE_TOKEN_MIN_LENGTH = 1;
export const VALIDATE_TOKEN_MAX_LENGTH = 6;

export const QUICKLINKS = {
  CRYPTO: {
    DEPOSIT: {
      title: "overview.balanceContainer.deposit.title",
      sub_title: "overview.balanceContainer.deposit.sub_title",
      support_key: "overview.balanceContainer.deposit.support_key",
      two_tone_icon: defineAsyncComponent(
        () => import("@/components/icons/IconDepositTwoTone.vue")
      ),
      icon: defineAsyncComponent(
        () => import("@/components/icons/IconDeposit.vue")
      ),
      name: "overview.balanceContainer.deposit.name",
      description: "",
      "bg-class": "",
    },
    WITHDRAW: {
      title: "overview.balanceContainer.withdraw.title",
      sub_title: "overview.balanceContainer.withdraw.sub_title",
      support_key: "overview.balanceContainer.withdraw.support_key",
      two_tone_icon: defineAsyncComponent(
        () => import("@/components/icons/IconWithdrawTwoTone.vue")
      ),
      icon: defineAsyncComponent(
        () => import("@/components/icons/IconWithdrawNew.vue")
      ),
      name: "overview.balanceContainer.withdraw.name",
      description: "",
      "bg-class": "",
    },
    TRADE: {
      title: "overview.balanceContainer.trade.title",
      sub_title: "overview.balanceContainer.trade.sub_title",
      support_key: "overview.balanceContainer.trade.support_key",
      two_tone_icon: defineAsyncComponent(
        () => import("@/components/icons/IconTradeCryptoTwoTone.vue")
      ),
      icon: defineAsyncComponent(
        () => import("@/components/icons/IconTrade.vue")
      ),
      name: "overview.balanceContainer.trade.name",
      description: "",
      "bg-class": "",
    },
  },
  FIAT: {
    DEPOSIT: {
      title: "Deposit Money",
      sub_title: "Deposit",
      two_tone_icon: defineAsyncComponent(
        () => import("@/components/icons/IconDepositTwoTone.vue")
      ),
      icon: defineAsyncComponent(
        () => import("@/components/icons/IconDeposit.vue")
      ),
      name: "fiat deposit",
      description: "fiat deposit",
      "bg-class": "",
    },
    WITHDRAW: {
      title: "Withdraw Money",
      sub_title: "Withdraw",
      two_tone_icon: defineAsyncComponent(
        () => import("@/components/icons/IconWithdrawTwoTone.vue")
      ),
      icon: defineAsyncComponent(
        () => import("@/components/icons/IconWithdraw.vue")
      ),
      name: "fiat withdraw",
      description: "",
      "bg-class": "",
    },
    TRADE: {
      title: "Trade",
      sub_title: "Trade",
      two_tone_icon: defineAsyncComponent(
        () => import("@/components/icons/IconTradeCryptoTwoTone.vue")
      ),
      icon: defineAsyncComponent(
        () => import("@/components/icons/IconTradeCrypto.vue")
      ),
      name: "fiat trade_crypto",
      description: "",
      "bg-class": "",
    },
  },
  GIFTCARD: {
    title: "overview.serviceContainer.quickLinks.giftCard.title",
    sub_title: "overview.serviceContainer.quickLinks.giftCard.sub_title",
    two_tone_icon: defineAsyncComponent(
      () => import("@/components/icons/IconGiftcardTwoTone.vue")
    ),
    icon: "",
    name: "overview.serviceContainer.quickLinks.giftCard.name",
    description: "overview.serviceContainer.quickLinks.giftCard.description",
    "bg-class": "",
  },
  AIRTIME: {
    title: "overview.serviceContainer.quickLinks.airtime.title",
    sub_title: "overview.serviceContainer.quickLinks.airtime.sub_title",
    two_tone_icon: defineAsyncComponent(
      () => import("@/components/icons/IconAirtimeTwoTone.vue")
    ),
    icon: "",
    name: "overview.serviceContainer.quickLinks.airtime.name",
    description: "overview.serviceContainer.quickLinks.airtime.description",
    "bg-class": "bg-primary-1",
  },
  ELECTRIC_BILL: {
    title: "overview.serviceContainer.quickLinks.electricBill.title",
    sub_title: "overview.serviceContainer.quickLinks.electricBill.sub_title",
    two_tone_icon: defineAsyncComponent(
      () => import("@/components/icons/IconEletricBillTwoTone.vue")
    ),
    icon: "",
    name: "overview.serviceContainer.quickLinks.electricBill.name",
    description:
      "overview.serviceContainer.quickLinks.electricBill.description",
    "bg-class": "bg-black",
  },
  VIRTUAL_CARD: {
    title: "overview.serviceContainer.quickLinks.virtualCard.title",
    sub_title: "overview.serviceContainer.quickLinks.virtualCard.sub_title",
    two_tone_icon: defineAsyncComponent(
      () => import("@/components/icons/IconVirtualCardTwoTone.vue")
    ),
    icon: "",
    name: "overview.serviceContainer.quickLinks.virtualCard.name",
    description: "overview.serviceContainer.quickLinks.virtualCard.description",
    "bg-class": "",
  },
  DATA_PLAN: {
    title: "overview.serviceContainer.quickLinks.dataPlan.title",
    sub_title: "overview.serviceContainer.quickLinks.dataPlan.sub_title",
    two_tone_icon: defineAsyncComponent(
      () => import("@/components/icons/IconDataTwoTone.vue")
    ),
    icon: "",
    name: "overview.serviceContainer.quickLinks.dataPlan.name",
    description: "overview.serviceContainer.quickLinks.dataPlan.description",
    "bg-class": "bg-secondary-main",
  },
  BILLS: {
    title: "overview.serviceContainer.quickLinks.bills.title",
    sub_title: "overview.serviceContainer.quickLinks.bills.sub_title",
    two_tone_icon: defineAsyncComponent(
      () => import("@/components/icons/IconBills.vue")
    ),
    icon: "",
    name: "overview.serviceContainer.quickLinks.bills.name",
    description: "overview.serviceContainer.quickLinks.bills.description",
    "bg-class": "",
  },
  TRANSFER: {
    title: "overview.serviceContainer.quickLinks.transfer.title",
    sub_title: "overview.serviceContainer.quickLinks.transfer.sub_title",
    two_tone_icon: defineAsyncComponent(
      () => import("@/components/icons/IconPaymentsTwoTone.vue")
    ),
    icon: "",
    name: "overview.serviceContainer.quickLinks.transfer.name",
    description: "overview.serviceContainer.quickLinks.transfer.description",
    "bg-class": "bg-teal-mask",
  },
  CABLE_TV: {
    title: "overview.serviceContainer.quickLinks.cableTv.title",
    sub_title: "overview.serviceContainer.quickLinks.cableTv.sub_title",
    two_tone_icon: defineAsyncComponent(
      () => import("@/components/icons/IconMonitor.vue")
    ),
    icon: "",
    name: "overview.serviceContainer.quickLinks.cableTv.name",
    description: "overview.serviceContainer.quickLinks.cableTv.description",
    "bg-class": "bg-accent-blue-200",
  },
  BET_TOP_UP: {
    title: "overview.serviceContainer.quickLinks.betTopUp.title",
    sub_title: "overview.serviceContainer.quickLinks.betTopUp.sub_title",
    two_tone_icon: defineAsyncComponent(
      () => import("@/components/icons/IconBetSlip.vue")
    ),
    icon: "",
    name: "overview.serviceContainer.quickLinks.betTopUp.name",
    description: "overview.serviceContainer.quickLinks.betTopUp.description",
    "bg-class": "bg-primary-purple",
  },
};

export const ROUTES = {
  LOGIN: {
    name: "Login",
    path: "/login",
    displayName: "",
  },
  MAINTENANCE: {
    name: "Maintenance",
    path: "/maintenance-mode",
  },
  CREATE_ACCOUNT: {
    name: "Create Account",
    path: "/create-account",
    displayName: "",
    TELL_US_MORE: {
      path: "/create-account/tell-us-more",
      name: "",
    },
  },
  RESET_PASSWORD: {
    name: "Reset Password",
    path: "/reset-password",
    displayName: "",
  },
  REDIRECT_TOKEN: {
    path: "/auth/callback",
    name: "redirect-token-authentication",
  },
  INDEX: {
    name: "Overview",
    path: "/",
    displayName: "overview.title",
  },
  TRANSACTION_OVERVIEW_DETAILS: {
    name: "transactionsOverviewDetails",
    path: "/transaction/:category/:id",
    displayName: "transactionsOverviewDetails",
  },
  WITHDRAW: {
    name: "Withdraw",
    path: "/withdraw",
    displayName: "withdraw",
  },
  DEPOSIT: {
    name: "Deposit",
    path: "/deposit",
    displayName: "deposit",
  },
  TRADE: {
    name: "Trade",
    path: "/trade",
    displayName: "Trade",
    DEPOSIT: {
      name: "Deposit",
      path: "/trade/deposit/:category",
      displayName: "deposit",
    },
    WITHDRAW: {
      name: "Withdraw",
      path: "/trade/withdraw/:category",
      displayName: "withdraw",
    },
    SWAP: {
      name: "Swap",
      path: "/trade/swap",
      displayName: "swap",
    },
  },
  GIFT_CARD_SELL: {
    name: "giftcards",
    path: "/giftcards",
    displayName: "giftcards",
    OFFER: {
      path: "/giftcards/sell/:id",
      name: "giftCardSellOffer",
    },
  },
  GIFT_CARD_TRADES: {
    name: "Gift Card Trades",
    path: "/giftcards/trades",
    displayName: "giftcards",
  },
  GIFT_CARD_BUY: {
    name: "Gift Card Buy",
    path: "/giftcards/buy",
    displayName: "giftcards",
    OFFER: {
      path: "/giftcards/buy/:name",
      name: "giftCardBuyOffer",
    },
  },
  GIFT_CARD_TRADE_CHAT: {
    name: "Gift Card Trade Chat",
    path: "/giftcards/trades/:id",
    displayName: "giftCardTrade",
  },
  BILLS: {
    name: "Bills",
    path: "/bills",
    displayName: "bills",
    AIRTIME: {
      name: "Airtime",
      path: "/bills/airtime",
      displayName: "buyAirtime",
    },
    ELECTRIC_BILL: {
      name: "Electric Bill",
      path: "/bills/electric-bill",
      displayName: "electricBill",
    },
    DATA_PLAN: {
      name: "Data Plan",
      path: "/bills/data-plan",
      displayName: "dataPlan",
    },
    CABLE_TV: {
      name: "Cable TV",
      path: "/bills/cable-tv",
      displayName: "cableTv",
    },
  },
  BET_TOP_UP: {
    name: "Bet Top Up",
    path: "/bet-top-up",
    displayName: "betTopUp",
  },
  TRANSACTIONS: {
    name: "transactionsTitle",
    path: "/transactions",
    displayName: "transactionsTitle",
    DETAILS: {
      name: "Transaction Details",
      path: "/transactions/:category/:id",
      displayName: "transactions",
    },
    SORT_BY: {
      name: "sortTransactions",
      path: "/transactions/sort",
      displayName: "sortTransactions",
    },
    SCHEDULED: {
      name: "scheduledTransaction",
      path: "/transactions/scheduled",
      displayName: "scheduledTransaction",
    },
    SCHEDULE_DETAILS: {
      name: "Scheduled Transaction Details",
      path: "/transactions/scheduled/:category/:id",
      displayName: "scheduledTransactionDetails",
    },
  },
  PROFILE: {
    name: "Profile",
    path: "/profile",
    displayName: "accountDetails",
    INFORMATION: {
      name: "Personal Information",
      path: "/profile/Information",
      displayName: "editProfile",
    },
    UPGRADE: {
      name: "Profile Upgrade",
      path: "/profile/upgrade",
      displayName: "profileUpgrade",
    },
    SECURITY: {
      name: "Login and Security",
      path: "/profile/security",
      displayName: "loginAndSecurity",
    },

    GENERAL: {
      name: "General",
      path: "/profile/general",
      displayName: "general",
    },
    GENERAL_SETTINGS: {
      name: "General",
      path: "/profile/general",
      displayName: "General",
    },

    BUSINESS: {
      name: "Business API",
      path: "/profile/business",
      displayName: "businessApi",
      CRYPTO_WITHDRAWAL: {
        name: "Business Crypto Withdrawal",
        path: "/profile/business/crypto-withdrawal",
        displayName: "businessCryptoWithdrawal",
        WITHDRAWAL_HISTORY: {
          name: "Business Withdrawal History",
          path: "/profile/business/crypto-withdrawal/history",
          displayName: "businessCryptoWithdrawalHistory",
        },
        WITHDRAWAL_POOL: {
          name: "Business Withdrawal Pool",
          path: "/profile/business/crypto-withdrawal/pool",
          displayName: "businessCryptoWithdrawalPool",
        },
        WEBHOOK_LOG: {
          name: "Business Webhook Log",
          path: "/profile/business/crypto-withdrawal/webhook-log",
          displayName: "businessCryptoWebhookLog",
          DETAILS: {
            name: "Business Webhook Log Details",
            path: "/profile/business/crypto-withdrawal/webhook-log/:id",
            displayName: "businessCryptoWebhookLogDetails",
          },
        },
      },
    },
    BUSINESS_VIRTUAL_CARDS: {
      name: "Business Virtual Cards",
      path: "/profile/business/virtual-cards",
      displayName: "businessVirtualCards",
    },
    BUSINESS_VIRTUAL_CARD: {
      name: "Business Virtual Card",
      path: "/profile/business/virtual-cards/:id",
      displayName: "businessVirtualCard",
    },
    BUSINESS_VIRTUAL_MASTER_CARD: {
      name: "Business Virtual Master Card",
      path: "/profile/business/virtual-cards/master-card",
      displayName: "businessVirtualMasterCard",
    },
    BUSINESS_VIRTUAL_VISA_CARD: {
      name: "Business Virtual Visa Card",
      path: "/profile/business/virtual-cards/visa",
      displayName: "businessVirtualVisaCard",
    },
    BUSINESS_DEPOSIT: {
      name: "Business Deposit",
      path: "/profile/business/deposit",
      displayName: "businessDeposit",
    },
    BUSINESS_DEPOSIT_ADDRESS: {
      name: "Business Deposit Address",
      path: "/profile/business/deposit/address",
      displayName: "businessDepositAddress",
    },
    BUSINESS_DEPOSIT_ADDRESS_DETAIL: {
      name: "Business Address Details",
      path: "/profile/business/deposit/address/details/:addressMemo",
      displayName: "businessAddressDetails",
    },
    BUSINESS_DEPOSIT_WALLETS: {
      name: "Business Deposit Wallets",
      path: "/profile/business/deposit/wallets",
      displayName: "businessDepositWallets",
      COLLECT: {
        name: "Business Deposit Wallets Collect",
        path: "/profile/business/deposit/wallets/:network/:asset",
        displayName: "businessDepositWalletsCollect",
      },
    },
    BUSINESS_DEPOSIT_HISTORY: {
      name: "Business Deposit History",
      path: "/profile/business/deposit/history",
      displayName: "businessDepositHistory",
    },
    BUSINESS_COLLECT_HISTORY: {
      name: "Business Collection History",
      path: "/profile/business/deposit/collection-history",
      displayName: "businessCollectionHistory",
    },
    BUSINESS_GAS_STATION: {
      name: "Business Gas Station",
      path: "/profile/business/deposit/gas-station",
      displayName: "businessGasStation",
    },
    BUSINESS_CALLBACK_LOG: {
      name: "Business Callback Log",
      path: "/profile/business/deposit/callback-log",
      displayName: "businessCallbackLog",
    },
    EMAIL_UPDATE: {
      name: "Update Email",
      path: "/profile/update/:identifier_type",
      displayName: "updateEmail",
    },
    USERNAME_UPDATE: {
      name: "Update username",
      path: "/profile/update/username",
      displayName: "updateUsername",
    },
    ACCOUNT: {
      name: "Account",
      path: "/profile/account",
      displayName: "accountSettings",
    },
  },
  WALLET: {
    name: "wallets",
    path: "/wallets",
    displayName: "wallets",
    ID: {
      name: "Wallet:ASSET",
      path: "/wallets/:category/:asset",
      displayName: "walletTitle",
      DETAILS: {
        name: "Single Transaction Details",
        path: "transactions/:id",
        displayName: "transactions",
      },
      WITHDRAW: {
        name: "Single Wallet Withdraw",
        path: "withdraw/:method(banks|momo|apex)",
        displayName: "singleWalletWithdraw",
      },
    },
  },
  CARDS: {
    name: "cards",
    path: "/cards",
    displayName: "cards",
    CREATE: {
      name: "Create Card",
      path: "/cards/create",
      displayName: "createCard",
    },
    ALL: {
      name: "All Cards",
      path: "/cards/all",
      displayName: "allCards",
    },
    FUND: {
      name: "Fund Card",
      path: "/cards/fund/:id",
      displayName: "fundCard",
    },
    TRANSACTION: {
      name: "Card Transaction",
      path: "/cards/:cardId/transaction/:transactionId",
      displayName: "cardTransaction",
    },
    WITHDRAW: {
      name: "Card Withdrawal",
      path: "/cards/withdraw/:id",
      displayName: "cardWithdrawal",
    },
    DETAILS: {
      name: "Card Details",
      path: "/cards/:id",
      displayName: "profile.cardDetails",
    },
    EDIT_LABEL: {
      name: "Edit Card Label",
      path: "/cards/edit-label/:id",
      displayName: "editCardLabel",
    },
    MORE: {
      name: "More",
      path: "/cards/more/:id",
      displayName: "more",
    },
  },
  REFERRALS: {
    name: "referralsTitle",
    path: "/referrals",
    displayName: "referralsTitle",
  },
};

export enum EVENTBUS {
  SHOW_QUICKLINK_DIALOG = "SHOW_QUICKLINK_DIALOG",
  LOGOUT = "LOGOUT",
  SHOW_QUICKLINK_VALIDATION_ERROR = "SHOW_QUICKLINK_VALIDATION_ERROR",
  SHOW_SERVICE_ERROR = "SHOW_SERVICE_ERROR",
  SHOW_TRANSACTION_DETAILS_DIALOG = "SHOW_TRANSACTION_DETAILS_DIALOG",
  SHOW_TRADE_DETAILS_DIALOG = "SHOW_TRADE_DETAILS_DIALOG",
  REFRESH_WALLETS = "REFRESH_WALLETS",
  LAUNCH_CAMPAIGNS = "LAUNCH_CAMPAIGNS",
}

export const countryInputGlobalOptions = {
  autoDefaultCountry: true,
  defaultCountry: "NG",
  preferredCountries: ["NG", "GH", "KE", "RW"],
  showSearchBox: true,
  dropdownOptions: {
    showDialCodeInSelection: true,
    showSearchBox: true,
  },
};

export const vueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  },
};

export const TRANSACTION_TITLE = "preview.common.reference";

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function toCamelCase(str: string): any {
  return str
    .split("-")
    .map((i, j) => (j == 0 ? i : capitalizeFirstLetter(i)))
    .join("");
}

export function objectsToCamelCaseObjects(obj: any) {
  let limits = {};
  for (const property in obj) {
    if (typeof obj[property] === "object") {
      Object.defineProperty(limits, toCamelCase(property), {
        value: objectsToCamelCaseObjects(obj[property]),
        writable: true,
      });
    } else {
      Object.defineProperty(limits, toCamelCase(property), {
        value: obj[property],
        writable: true,
      });
    }
  }
  return limits;
}

export const AXIOS_ERROR_NAME = "AxiosError";

export const RECAPTCHA_VALIDATION_TOKEN_KEY = "request_validation_token";

export const RECAPTCHA_PASSWORD_RESET_TOKEN_KEY =
  "request_password_reset_token";

export const ALLOWED_CURRENCIES_FOR_WITHDRAWAL = ["ghs", "ngn", "zmw"];
export const ALLOWED_CURRENCIES_FOR_DEPOSIT = ["ngn"];

export enum WITHDRAWAL_TYPES {
  MOBILE_MONEY = "mobile_money",
  BANK_TRANSFER = "bank_transfer",
}

export const DEFAULT_CURRENCY = "ngn";

export enum VERIFICATION_PROVIDER {
  METAMAP = "meta-map",
  AIPRISE = "aiprise",
}

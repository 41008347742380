import { createI18n } from "vue-i18n";
import { messages } from "@/locales";
import { getUserLanguage } from "@/utils";


export const i18n = createI18n({
  locale: getUserLanguage(),
  legacy: false,
  fallbackLocale: "en",
  messages,
});

import { setupInterceptors } from "@/middleware/auth";
import useZendesk from "@/plugin/zendesk";
import { moneyFormat } from "@/utils";
import {
  countryInputGlobalOptions,
  vueQueryPluginOptions,
} from "@/utils/consts";
import Toaster from "@meforma/vue-toaster";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import "swiper/css";
import "swiper/css/navigation";
import { createApp } from "vue";
import VueGtag from "vue-gtag";
import { VueQueryPlugin } from "vue-query";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { i18n } from '@/plugins/i18n'
import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import vue3GoogleLogin from "vue3-google-login";
import { VueFire, VueFireAuth } from "vuefire";
import App from "./App.vue";
import "./assets/styles/main.css";
import { initSentry } from "./plugin/sentry";
import { router as setupRouter } from "./router";
import "./serviceWorker";
import { firebaseApp } from "./utils/fireStore";
//@ts-ignore
import VueAppleLogin from "vue-apple-login";
import VueLazyLoad from "vue3-lazyload";


const pinia = createPinia();
const zendesk = useZendesk();
NProgress.configure({ showSpinner: false, easing: "ease", speed: 800 });

// Rudderstack
const WRITE_KEY = import.meta.env.VITE_APP_RUDDER_WRITE_KEY || "123456";
const DATA_PLANE_URL =
  import.meta.env.VITE_APP_RUDDER_DATA_PLANE_URL ||
  "https://www.apexnetwork.co";

// GTAG
const GTAG_ID = import.meta.env.VITE_APP_GTAG_MEASUREMENT_ID || "G-123456";

// RECAPTCHA
const RECAPTCHA_SITE_KEY =
  import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY || "123456";

// SSO
const APPLE_CLIENT_ID = import.meta.env.VITE_APP_APPLE_CLIENT_ID;
const APPLE_REDIRECT_URI = import.meta.env.VITE_APP_APPLE_REDIRECT_URI;
const GOOGLE_SSO = import.meta.env.VITE_APP_GOOGLE_SSO;

if (!APPLE_REDIRECT_URI) {
  console.log("Apple redirect sso not found");
}
if (!GOOGLE_SSO) {
  console.log("Google sso not found");
}

const initializeApp = async () => {
  setupInterceptors();
  const router = await setupRouter;
  const app = createApp(App);
  pinia.use(piniaPluginPersistedstate);
  app.use(pinia);
  app.directive("animate", (el, binding) => {
    el.className +=
      " animate-pulse bg-gray-200 dark:border dark:border-dark dark:bg-neutral-1";
  });

  if (import.meta.env.MODE === "production") {
    window.addEventListener("load", () => {
      initSentry(app, router);
    });
  }

  app
    .directive("format", (el, binding) => {
      el.textContent = moneyFormat(+el.textContent);
    })
    .use(Toaster)
    .use(VueQueryPlugin, vueQueryPluginOptions)
    .use(VueTelInput as any, countryInputGlobalOptions)
    .use(router)
    .use(i18n)
    .use(zendesk)
    .use(VueReCaptcha, {
      siteKey: RECAPTCHA_SITE_KEY,
      loaderOptions: {
        autoHideBadge: true,
      },
    })
    .use(VueGtag, {
      appName: "Apex Network Trading Limited",
      pageTrackerScreenviewEnabled: true,
      config: { id: GTAG_ID },
    })
    // .use(rudderstack, {
    //   writeKey: WRITE_KEY,
    //   dataPlaneUrl: DATA_PLANE_URL,
    // })
    .use(VueFire, {
      firebaseApp,
      modules: [VueFireAuth()],
    })
    .use(vue3GoogleLogin, {
      clientId: GOOGLE_SSO,
    })
    .use(VueAppleLogin, {
      clientId: APPLE_CLIENT_ID,
      scope: "email",
      redirectURI: APPLE_REDIRECT_URI,
      state: Date.now().toString(),
      usePopup: true,
    })
    .use(VueLazyLoad, {
      loading: "",
      error: "",
      lifecycle: {
        loading: (el) => {},
        error: (el) => {},
        loaded: (el) => {},
      },
    })

    .mount("#app");
};

initializeApp().then();

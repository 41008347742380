import auth from './auth.json';
import overview from './overview.json';
import withdraw from './withdraw.json'
import routeDisplayNames from './routes/displayName.json'
import trade from './trade.json'
import headerAccountDropdown from './headerAccountDropdown.json'
import transaction from './transactions.json'
import giftcards from './giftcards.json'
import cards from './cards.json'
import referrals from './referrals.json'
import profile from './profile.json'
import appTab from './appTab.json'
import businessIntegrations from './businessIntegrations.json'


export default {
  ...auth,
  ...overview,
  ...withdraw,
  ...routeDisplayNames,
  ...trade,
  ...headerAccountDropdown,
  ...transaction,
  ...giftcards,
  ...cards,
  ...referrals,
  ...profile,
  ...appTab,
  ...businessIntegrations
}